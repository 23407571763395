@import url(https://fonts.cdnfonts.com/css/perpetua-titling-mt);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Perpetua Titling MT', sans-serif;
}

/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&display=swap');*/

.light {
  background-color: black;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
}

.mainCon{
  color: white;
  font-size: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 100%;
   margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  background-color: #191c36c7;

}

.introduction{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
}

.totalSupply{
  color: white;
  text-align: center;
  font-size: 40px;
  margin-top: 10px;
}

.price{
  font-size: 25px;
  color: white;
  text-align: center;
}

.btnfos-0-3{
  font-size: 25px;
  border: 2px solid #ff0000;
  background-color: #00000000;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  color: white;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
 }

 .btnfos-0-3:hover{
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
 }

 .btnfos-0-4{
  font-size: 25px;
  border: 2px solid #ff0000;
  background-color: #00000000;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  color: white;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  }

 .btnfos-0-4:hover{
   box-shadow: 0 0 15px #f35151;
 }

 
.nameSection{
  color: white;
  margin-bottom: 5px;
}

input{
  width: 300px;
  background-color: #00000075;
  border: 2px solid white;
  border-radius: 6px;
  color: #ff0000;
  padding: 5px;

}

.input{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
}

.mintbuttondiv{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
}

textarea{
  width: 300px;
  background-color: #00000075;
  border: 2px solid white;
  color: #ff0000;
  border-radius: 6px;
}

.mainSectionInput{
  margin-bottom: 30px;
}


.successfully {
  text-align: center;
  margin-top: 3%;
  color: #17a3b6;
  font-size: 30px;
}

.errorMessage {
  margin-top: 3%;
  font-size: 20px;
  color: #fc4741;
  text-align: center;
}

.loadingContainer {
  margin-top: 3%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: white;
}

.loadTextSub {
  text-align: center;
  color: rgba(255, 255, 255, 0.815);
  font-size: 16px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
 }

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: #ff0000;
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 #ff0000,
      .5em 0 0 rgba(0, 0, 0, 0);
   }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: #ff0000;
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 #ff0000,
      .5em 0 0 rgba(0, 0, 0, 0);
   }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.os{
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  cursor: pointer;
}
